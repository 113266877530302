import React from "react";
import {
  ActionButton,
  ButtonCancel,
  ButtonImport,
  ModalComponentWrapper,
  TitleConfirm,
} from "./styled";

const ModalConfirm = ({ onConfirm, onCancelGen, visibleModal, onCancelIcon}) => {
  const handleOK = async () => {
    onConfirm()
  };
  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onConfirm}
      onCancel={onCancelIcon}
      centered
    >
      <TitleConfirm>他の受講予定講座を生成しますか。<br/>生成しない場合はキャンセルしてください。</TitleConfirm>
      <ActionButton>
        <ButtonImport onClick={handleOK}>新規生成</ButtonImport>
        <ButtonCancel onClick={onCancelGen}>キャンセル</ButtonCancel>
      </ActionButton>
    </ModalComponentWrapper>
  );
};

export default ModalConfirm;
