import React, { useState,useEffect } from "react";
import {
  ModalComponentWrapper,
  WrapperLabel,
  EndCol,
  StartCol,
} from "./styled";
import { Col, Row, Form, Select, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FormProvider, useForm } from "react-hook-form";
import { FailureBtn } from "Components/Button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import NormalButton from "Components/Button/NormalBtn";
import { convertDataToNumber } from "Utils/convertValue";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import _ from "lodash";
import { textNoData } from "Utils/data-default";
i18next.loadNamespaces(["student"]);

const levelCharacter = [
  { label: "とても低い", value: 1},
  { label: "低い", value: 2 },
  { label: "普通", value: 3 },
  { label: "高い", value: 4 },
  { label: "とても高い", value: 5 },
];

const ModalCharacterStudent = ({
  onOk,
  onCancel,
  visibleModal,
  isGenCharacter,
}) => {
  const [t] = useTranslation("student");
  const [loading, setLoading] = useState(false);
  const [excitement, setExcitement] = useState(3);
  const [upwardTrend, setUpwardTrend] = useState(3);
  const [praiseResistance, setPraiseResistance] = useState(3);
  const [autonomy, setAutonomy] = useState(3);
  const [stressTolerance, setStressTolerance] = useState(3);
  const {
    currentStudentInformation
  } = useSelector((state) => state.student);
  const validationSchema = yup
    .object({
      奮起度: yup
        .string()
        .required(t("student:message.field_is_required_excitement")),
      上昇志向: yup
        .string()
        .required(t("student:message.field_is_required_upward_trend")),
      誉め耐性: yup
        .string()
        .required(t("student:message.field_is_required_praise_resistance")),
      自立性: yup
        .string()
        .required(t("student:message.field_is_required_autonomy")),
      ストレス耐性: yup
        .string()
        .required(t("student:message.field_is_required_stress_tolerance")),
    })
    .required();
  
  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      奮起度: currentStudentInformation?.dataCharacter?.奮起度 ? currentStudentInformation?.dataCharacter?.奮起度  : 3,
      上昇志向: currentStudentInformation?.dataCharacter?.上昇志向 ? currentStudentInformation?.dataCharacter?.上昇志向 : 3,
      誉め耐性: currentStudentInformation?.dataCharacter?.誉め耐性 ? currentStudentInformation?.dataCharacter?.誉め耐性 :  3,
      自立性: currentStudentInformation?.dataCharacter?.自立性 ? currentStudentInformation?.dataCharacter?.自立性 : 3,
      ストレス耐性: currentStudentInformation?.dataCharacter?.ストレス耐性 ? currentStudentInformation?.dataCharacter?.ストレス耐性 : 3,
    },
  });
  useEffect(() => {
    if (!_.isEmpty(currentStudentInformation))  {
      setExcitement(currentStudentInformation?.dataCharacter?.奮起度 || 3);
      setAutonomy(currentStudentInformation?.dataCharacter?.自立性 || 3);
      setUpwardTrend(currentStudentInformation?.dataCharacter?.上昇志向 || 3);
      setPraiseResistance(currentStudentInformation?.dataCharacter?.誉め耐性 || 3);
      setStressTolerance(currentStudentInformation?.dataCharacter?.ストレス耐性 || 3);
    }
  },[currentStudentInformation])
  const {
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = form;
  const onSubmit = async (data) => {
    setLoading(true);
    onOk(convertDataToNumber(data));
    setLoading(false);
  };
  const handleExcitement = (e) => {
    setValue("奮起度", e);
    setExcitement(e);
    clearErrors(["奮起度"]);
  };
  const handleUpwardTrend = (e) => {
    setValue("上昇志向", e);
    setUpwardTrend(e);
    clearErrors(["上昇志向"]);
  };
  const handlePraiseResistance = (e) => {
    setValue("誉め耐性", e);
    setPraiseResistance(e);
    clearErrors(["誉め耐性"]);
  };
  const handleAutonomy = (e) => {
    setValue("自立性", e);
    setAutonomy(e);
    clearErrors(["自立性"]);
  };
  const handleStressTolerance = (e) => {
    setValue("ストレス耐性", e);
    setStressTolerance(e);
    clearErrors(["ストレス耐性"]);
  };
  const setAllValues = () => {
    if (!_.isEmpty(currentStudentInformation) &&  currentStudentInformation.hasOwnProperty('dataCharacter')) { 
      setExcitement(currentStudentInformation?.dataCharacter?.奮起度 || 3);
      setAutonomy(currentStudentInformation?.dataCharacter?.自立性 || 3);
      setUpwardTrend(currentStudentInformation?.dataCharacter?.上昇志向 || 3);
      setPraiseResistance(currentStudentInformation?.dataCharacter?.誉め耐性 || 3);
      setStressTolerance(currentStudentInformation?.dataCharacter?.ストレス耐性 || 3);
    }
    else {
      setExcitement(3);
      setAutonomy(3);
      setUpwardTrend(3);
      setPraiseResistance(3);
      setStressTolerance(3);
    }
  }
  const onCancelModal = () => {
    reset();
    clearErrors();
    setAllValues();
    onCancel()
  }
  return (
    <ModalComponentWrapper
      open={visibleModal}
      title={t("select_charater")}
      onOk={onOk}
      onCancel={onCancelModal}
      centered
    >
      <FormProvider {...form}>
        <form
          id="character-student-form"
          method="post"
          autoComplete="off"
          defaultValues
        >
          <Row gutter={[32]}>
            <Col span={24}  >
              <Form.Item
                label={<WrapperLabel>
                  {t("excitement")}
                  <Tooltip title={t("tooltip_excitement")}>
                    <QuestionCircleOutlined className="icon-question"/>
                  </Tooltip>                           
                </WrapperLabel>}
                validateStatus={errors.奮起度 ? "error" : ""}
                help={errors?.奮起度?.message}
              >
                <Select
                  className="select_type"
                  showSearch
                  label={t("excitement")}
                  name="奮起度"
                  options={levelCharacter}
                  placeholder={t("excitement")}
                  onChange={handleExcitement}
                  value={excitement}
                  allowClear
                  notFoundContent={textNoData}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                />
              </Form.Item>
              <Form.Item
                label={<WrapperLabel>
                  {t("praise_resistance")}
                  <Tooltip title={t("tooltip_praise_resistance")}>
                    <QuestionCircleOutlined className="icon-question"/>
                  </Tooltip> 
                </WrapperLabel>}
                validateStatus={errors.誉め耐性 ? "error" : ""}
                help={errors?.誉め耐性?.message}
              >
                <Select
                  className="select_type"
                  showSearch
                  label={t("praise_resistance")}
                  name="誉め耐性"
                  options={levelCharacter}
                  placeholder={t("praise_resistance")}
                  onChange={handlePraiseResistance}
                  allowClear
                  value={praiseResistance}
                  notFoundContent={textNoData}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                />
              </Form.Item>
              <Form.Item
                label={<WrapperLabel>
                  {t("stress_tolerance")}
                  <Tooltip title={t("tooltip_stress_tolerance")}>
                    <QuestionCircleOutlined className="icon-question"/>
                  </Tooltip>
                </WrapperLabel>}
                validateStatus={errors.ストレス耐性 ? "error" : ""}
                help={errors?.ストレス耐性?.message}
              >
                <Select
                  className="select_type"
                  showSearch
                  label={t("stress_tolerance")}
                  name="ストレス耐性"
                  options={levelCharacter}
                  placeholder={t("stress_tolerance")}
                  onChange={handleStressTolerance}
                  allowClear
                  value={stressTolerance}
                  notFoundContent={textNoData}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                />
              </Form.Item>
              <Form.Item
                label={<WrapperLabel>
                  {t("upward_trend")}
                  <Tooltip title={t("tooltip_upward_trend")}>
                    <QuestionCircleOutlined className="icon-question"/>
                  </Tooltip> 
                </WrapperLabel>}
                validateStatus={errors.上昇志向 ? "error" : ""}
                help={errors?.上昇志向?.message}
              >
                <Select
                  className="select_type"
                  showSearch
                  label={t("upward_trend")}
                  name="上昇志向"
                  options={levelCharacter}
                  placeholder={t("upward_trend")}
                  onChange={handleUpwardTrend}
                  allowClear
                  value={upwardTrend}
                  notFoundContent={textNoData}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                />
              </Form.Item>
              <Form.Item
                label={<WrapperLabel>
                  {t("autonomy")}
                  <Tooltip title={t("tooltip_autonomy")}>
                    <QuestionCircleOutlined className="icon-question"/>
                  </Tooltip>
                </WrapperLabel>}
                validateStatus={errors.自立性 ? "error" : ""}
                help={errors?.自立性?.message}
              >
                <Select
                  className="select_type"
                  showSearch
                  label={t("autonomy")}
                  name="自立性"
                  options={levelCharacter}
                  placeholder={t("autonomy")}
                  onChange={handleAutonomy}
                  allowClear
                  value={autonomy}
                  notFoundContent={textNoData}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                />
              </Form.Item>
            </Col>
          </Row>
        </form>
      </FormProvider>
      <Row justify={"center"} gutter={[32]}>
        <EndCol span={12} lg={12}>
          <NormalButton onClick={onCancelModal}>{t("cancel")}</NormalButton>
        </EndCol>
        <StartCol span={12} xl={12}>
          <FailureBtn
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            disabled={loading}
          >
            {t("confirm")}{" "}
          </FailureBtn>
        </StartCol>
      </Row>
    </ModalComponentWrapper>
  );
};

export default ModalCharacterStudent;
